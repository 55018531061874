<script setup lang="ts">
const { canSeeRewardSystem } = useUserState();

const howCycleWorksSteps = computed(() =>
  canSeeRewardSystem.value
    ? [
        { tag: 'DAY 1-7', label: 'Sample Order Window' },
        { tag: 'DAY 8', label: 'Sample Surveys Open' },
        { tag: 'Last day of month', label: 'Sample Surveys Expires' },
      ]
    : [
        { tag: 'DAY 1', label: 'Tokens Issued' },
        { tag: 'DAY 1-7', label: 'Sample Order Window' },
        { tag: 'DAY 8', label: 'Surveys Open and Promo Offers Activate' },
        { tag: 'Last day of month', label: 'Sample Survey Expires' },
      ]
);
</script>

<template>
  <div class="flex flex-col items-center justify-center gap-6">
    <TnHeading element="h2" class="text-center !text-4xl"
      >HOW CYCLES WORK</TnHeading
    >

    <div class="w-full px-8">
      <div
        class="bg-beige-200 relative mx-auto flex w-full max-w-[700px] flex-col items-center justify-center gap-8 rounded-[20px] border border-b-4 border-black px-6 py-6"
      >
        <div
          class="border-tnNeutral-900 absolute bottom-[38px] hidden w-3/4 border md:block"
        />

        <div class="z-10 flex w-full flex-col gap-6 md:flex-row">
          <div
            v-for="(step, i) in howCycleWorksSteps"
            :key="i"
            class="space-y-1 text-center md:flex md:flex-col md:space-y-0"
            :class="{
              'basis-1/3': canSeeRewardSystem,
              'basis-1/4': !canSeeRewardSystem,
            }"
          >
            <div class="font-secondary mt-auto inline-block md:order-2">
              <span
                class="bg-beige-500 inline-block text-nowrap rounded-full border border-b-[3px] border-black px-4 py-1 text-xs font-semibold"
              >
                {{ step.tag }}
              </span>
            </div>
            <div
              class="text-tnNeutral-700 mb-auto text-xs font-medium md:order-1 md:pb-4"
            >
              {{ step.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
